import { Product, ProductEnum } from '@uc/web/shared/data-models';

export const isValidProduct = (product: any) => {
	const validProducts: Product[] = [
		ProductEnum.Undergraduate,
		ProductEnum.Postgraduate,
		ProductEnum.Clearing,
	];

	return validProducts.includes(product);
};
