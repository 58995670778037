export function createObjectFromString(string: string) {
	const obj: { [key: string]: string[] } = {};

	const keyValuePairs = string?.split('&');
	keyValuePairs?.forEach((pair) => {
		const [key, value] = pair.split('=');
		if (key && value) {
			const values = value?.split('_').filter(Boolean);
			if (values.length > 0) {
				obj[key] = values;
			}
		}
	});

	return obj;
}
