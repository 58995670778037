export enum ClientFeatures {
	/**
	 * Enables the undergraduate profile.
	 */
	Undergraduate = 'UNDERGRADUATE_PROFILE',
	/**
	 * Enables the postgraduate profile.
	 */
	Postgraduate = 'POSTGRADUATE_PROFILE',
	/**
	 * Enables the clearing profile.
	 */
	Clearing = 'CLEARING_PROFILE',
	/**
	 * Enables the undergraduate preview mode.
	 */
	UnderPreview = 'UNDERGRADUATE_PREVIEW_MODE',
	/**
	 * Enables the postgraduate preview mode.
	 */
	PostPreview = 'POSTGRADUATE_PREVIEW_MODE',
	/**
	 * Enables the clearing preview mode.
	 */
	ClearingPreview = 'CLEARING_PREVIEW_MODE',
	/**
	 * Enables the clearing profile to showcase call buttons
	 * and allows the user to call.
	 */
	Calls = 'CALLS',
	/**
	 * Enables the 'Register Interest' button appearing for Clearing profile.
	 */
	RegisterInterest = 'REGISTER_INTEREST',
	/**
	 * Enables the 'Visit Website' button appearing for Clearing profile.
	 */
	ClearingVisitWebsite = 'WEBSITE_CLICKS',
	/**
	 * This enables students to see university data from 'Calls',
	 * and 'Website Clicks' (when signed in).
	 */
	ShowHiddenData = 'SHOW_HIDDEN_DATA',
	/**
	 * Enables a custom message if the postgraduate prospectus
	 * order cannot be processed.
	 */
	ErrorMessageInPostProspectus = 'CUSTOM_ERROR_MESSAGE_IN_POSTGRADUATE_PROSPECTUS',
	/**
	 * Enables a custom message if the undergraduate prospectus
	 * order cannot be processed.
	 */
	ErrorMessageInUnderProspectus = 'CUSTOM_ERROR_MESSAGE_IN_UNDERGRADUATE_PROSPECTUS',
	/**
	 * Enables the university who have undergraduate and postgraduate courses but
	 * only have a Postgraduate profile to set the landing page to Postgraduate
	 * instead of Undergraduate.
	 */
	PostgraduateLandingPage = 'POSTGRADUATE_TAB_AS_LANDING_PAGE',
}

export type ClientFeatureOptions = `${ClientFeatures}`;

/**
 * @FeaturedUndergraduate - Enables the undergraduate profile.
 * - should be set to true when the API returns UNDERGRADUATE_PROFILE || UNDERGRADUATE_PREVIEW_MODE within the paid_featured array
 * - should be checked against the ClientFeatures.Undergraduate || ClientFeatures.PostPreview enum
 */
export type FeaturedUndergraduate = boolean;

/**
 * @FeaturedPostgraduate - Enables the postgraduate profile.
 * - should be set to true when the API returns POSTGRADUATE_PROFILE || POSTGRADUATE_PREVIEW_MODE within the paid_featured array
 * - should be checked against the ClientFeatures.Postgraduate || ClientFeatures.PostPreview enum
 */
export type FeaturedPostgraduate = boolean;

/**
 * @FeaturedPostgraduate - Enables the clearing profile.
 * - should be set to true when the API returns CLEARING_PROFILE || CLEARING_PREVIEW_MODE within the paid_featured array
 * - should be checked against the ClientFeatures.Clearing || ClientFeatures.ClearingPreview enum
 */
export type FeaturedClearing = boolean;

/**
 * @FeaturedCalls - Enables the clearing Call button.
 * - should be set to true when the API returns CALLS within the paid_featured array
 * - should be checked against the ClientFeatures.Calls
 */
export type FeaturedCalls = boolean;

/**
 * @FeaturedRegisteredInterest - Enables the clearing Register Interest button.
 * - should be set to true when the API returns REGISTER_INTEREST within the paid_featured array
 * - should be checked against the ClientFeatures.RegisterInterest
 */
export type FeaturedRegisteredInterest = boolean;

/**
 * @FeaturedClearingVisitWebsite - Enables the clearing Visit Website button.
 * - should be set to true when the API returns WEBSITE_CLICKS within the paid_featured array
 * - should be checked against the ClientFeatures.ClearingVisitWebsite
 */
export type FeaturedClearingVisitWebsite = boolean;
