import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class BotsIndexService {
	private readonly _disallowedQueryparams = [
		'offset',
		'year',
		'ctype',
		'mode',
		'utype',
		'level',
		'date',
	];

	constructor(private _meta: Meta) {}

	updateRobotsMetaTag(url: string) {
		const queryParams = this._getQueryParams(url);
		const hasDisallowedQueryparams = this._disallowedQueryparams.some((param) =>
			queryParams.includes(param),
		);

		const index = hasDisallowedQueryparams ? 'noindex' : 'index';
		this._meta.updateTag({ name: 'robots', content: index });
	}

	private _getQueryParams(url: string): string[] {
		const arrayOfParams: string[] = [];
		const query = url.split('?')[1];
		if (!query) return [];

		const queryParams = query.split('&');

		queryParams.forEach((param) => {
			const [key] = param.split('=');
			arrayOfParams.push(key);
		});

		return arrayOfParams;
	}
}
